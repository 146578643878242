import { render, staticRenderFns } from "./AuctionPage.vue?vue&type=template&id=fdc66334&scoped=true"
import script from "./AuctionPage.vue?vue&type=script&lang=js"
export * from "./AuctionPage.vue?vue&type=script&lang=js"
import style0 from "./AuctionPage.vue?vue&type=style&index=0&id=fdc66334&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdc66334",
  null
  
)

export default component.exports