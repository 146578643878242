<template>
  <div style="margin-left: 10px">
    <div>
      <div class="row flex align-center page-head-content justify-between">
        <div class="col-md-10 page-title">
          <v-card-title>
            <span id="title" class="text-h5">{{ ('Fleet Used Cars') }} | </span
            ><span id="title_status">{{
              (workflow_state) }}</span>
          </v-card-title>

        </div>

        <v-row class="align-end justify-end" style="margin-bottom: 1rem;position: absolute;">
          <v-menu offset-y v-if="workflow_state == 'Price Proposal'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small color="primary" class="printButton" style="margin-right: 1rem">
                {{ 'Print' }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="Print_format($event,'ar')">Arabic</v-list-item>
              <v-list-item @click="Print_format($event,'en')">English</v-list-item>
            </v-list>
          </v-menu>
          <v-btn
          v-if="changedKeys && Has_Access('Auction','write')"
            color="primary"
            height="28"
            min-width="60"
            style="margin-right: 1rem"
            @click="save"
            class="saveButton"
          >
            {{ 'Save' }}
          </v-btn>
          <v-menu offset-y v-if="Has_Access('Auction','import') && workflow_state == 'Draft' && (selectedAuctionType == 'Auction' || (selectedAuctionType == 'Wholesale' && carsInformationUnavailable == 0))">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small color="primary" class="importButton" style="margin-right: 1rem">
                {{ 'Import' }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-btn @click="downloadTemplate" color="primary" text style="margin-bottom: 0.8rem">
                Download Template
              </v-btn>
                <div style="margin-left: 2.1rem;">
                    <!-- 1. Create the button that will be clicked to select a file -->
                    <v-btn 
                        color="primary"  
                        :loading="isSelecting" 
                        @click="handleFileImport"
                        text
                    >
                        {{Upload_file_label}}
                    </v-btn>

                    <!-- Create a File Input that will be hidden but triggered with JavaScript -->
                    <input 
                        ref="uploader" 
                        class="d-none" 
                        type="file" 
                        @change="onImportFileChange"
                    >
                </div>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="!changedKeys" v-bind="attrs" v-on="on" small color="primary" class="actionButton">
                {{ 'Actions' }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="item in workflow_actions" :key="item.action" class="actionItem">
                <v-list-item-content @click="set_workflow_action($event, item)" >
                  <v-list-item-title>{{ item.action }}</v-list-item-title >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          
          


        </v-row>
      </div>

      <form>
        <div class="row form-section card-section visible-section">
          <div class="form-column col-sm-6">
            <v-autocomplete
              v-model="selectedSupplier"
              :items="supplier"
              :label="supplier_label"
              item-text="supplier_name"
              item-value="name"
              outlined
              @change="onChange($event)"
              :disabled="is_disabled"
            >
            </v-autocomplete>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              @change="onChange($event)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDate"
                  :label= "auction_date_label"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @change="onChange($event)"
                  :disabled="is_disabled"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedDate"
                @input="menu2 = false"
                @change="onChange($event)"
              ></v-date-picker>
            </v-menu>
            <v-spacer></v-spacer>
            <v-autocomplete
              v-model="selectedCity"
              :items="city"
              :label="auction_city_label"
              item-text="city_name"
              item-value="name"
              outlined
              @change="onChange($event)"
              :disabled="is_disabled"
            ></v-autocomplete>
            <label>{{ ('Auction Document') }}</label>
            <v-file-input
              v-if="!file_url"
              v-model="auctionDocument"
              :label="Choose_file_label"
              outlined
              @change="onChange($event)"
              :disabled="is_disabled"
            ></v-file-input>
            <div class="control-input-wrapper" v-if="file_url">
              <div class="control-input">
                <div 
                  class="attached-file flex justify-between align-center"
                  style="display: flex"
                >
                  <div class="ellipsis">
                    <i class="fa fa-paperclip"></i>
                    <a
                      class="attached-file-link"
                      target="_blank"
                      :href="file_url"
                      >{{ file_name }}</a
                    >
                  </div>
                  <div v-if="this.workflow_state == 'Draft'">
                    <a 
                      class="btn btn-xs btn-default"
                      data-action="clear_attachment"
                      @click="clear_attachment($event)"
                      
                      >Clear</a
                    >
                  </div>
                </div>
              </div>
              <div
                class="control-value like-disabled-input"
                style="display: none"
              ></div>
              <p class="help-box small text-muted"></p>
            </div>
          </div>
          <div class="form-column col-sm-6">
            <v-select v-model="selectedAuctionType" :items="auctionTypes" :label="auction_type_label" outlined
              @change="onSelectedAuctionType($event)" :disabled="is_disabled"></v-select>
            <div class="form-group" v-if="selectedAuctionType === 'Wholesale'">
              <v-checkbox v-model="carsInformationUnavailable" :label="cars_info_unavailable_label"
                @change="onCarsInfoClicked($event)" :disabled="is_disabled"></v-checkbox>
            </div>
            <div v-if="carsInformationUnavailable && selectedAuctionType === 'Wholesale'">
              <v-text-field v-model="number_of_cars" :label="number_of_cars_label" outlined @keypress="onKeyPress"
              @change="onChange($event)" :disabled="is_disabled">
              </v-text-field>
            </div>
            <div>
              <v-text-field v-model="location" :label="auction_location_label" outlined @change="onChange($event)"
                :disabled="is_disabled">
              </v-text-field>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row form-section card-section visible-section">
      <div class="form-column col-sm-12">
        <AuctionCarsPage
          ref="cars_items"
          :editable_by="editable_by"
          :auction_id="auction_id"
          :status="workflow_state"
          :auction_type="selectedAuctionType"
          :cars_information_unavailable="carsInformationUnavailable"
          :addRowBtnDisabled="addRowBtnDisabled"
          :auction_date="selectedDate"
          :dialog.sync="dialog"
          :selected_item="selected_item"
          :purchase_status="purchase_status"
          :auction_options="auction_options"
          :clickCount="dialog_index"
          :header_name="header_name"
          :redMakeYearIndexes="redMakeYearIndexes"
          :redOdometerIndexes="redOdometerIndexes"
          :event_workflow="event_workflow"
          :item_workflow="item_workflow"
          :carsInformationUnavailable="carsInformationUnavailable"
          @childEvent="handleChildEvent"
          @collFrm_child="set_workflow_action"
        >
        </AuctionCarsPage>
        <ConfirmationDialog
          ref="ConfirmationDialog"
          @childEvent="handleChildEvent"
        >
        </ConfirmationDialog>
      </div>
    </div>
    <CButton component="input" type="button" color="primary" value="Input" />
    <v-dialog v-model="showDatePicker" max-width="290">
      <v-date-picker
        v-model="selectedDate"
        @input="showDatePicker = false"
      ></v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import AuctionCarsPage from './AuctionCarsPage.vue';
import Vue from 'vue';
import ToastMsg from './ToastMsg.vue';
import ConfirmationDialog from './ConfirmationDialog.vue';



export default {
  props: ['auction_items'],
  data: function () {

    return {
      redMakeYearIndexes: [],
      redOdometerIndexes: [],
      event_workflow: "",
      item_workflow: "",
      header_name: 'Cars Need Action',
      dialog_index: 0,
      pos_profile: 'Syarah',
      workflow_state: 'New',
      purchases: [],
      assigneeDict: {},
      dialog: false,
      auction_id: "",
      supplier: [],
      selectedSupplier: null,
      date: '',
      isSelecting: false,
      city: [],
      selectedCity: null,
      type: "",
      number_of_cars: null,
      location: "",
      cars_information_unavailable: 0,
      selectedDate: null,
      showDatePicker: false,
      selectedAuctionType: null,
      auctionTypes: [('Auction'), ('Wholesale')],
      carsInformationUnavailable: false,
      addRowBtnDisabled: false,
      auctionDocument: null,
      importFile: null,
      menu2: false,
      supplier_label: ("Select Supplier"),
      auction_type_label: ("Select Auction Type"),
      Choose_file_label: ("Choose A File"),
      Upload_file_label: ("Upload File"),
      auction_city_label: ("Select Auction City"),
      auction_date_label: ("Select Auction Date"),
      auction_location_label: ("Type Auction Location"),
      number_of_cars_label: ('Number of Cars'),
      cars_info_unavailable_label: ('Cars Information Unavailable'),
      isSaveButtonVisible: false,
      changedKeys: false,
      is_disabled: false,
      workflow_actions: [],
      auction_options: [],
      remove_options: [],
      is_needed_action: false,
      editable_by: null,
      file_url: null,
      file_name: null,
      cahnge_workflow_Price_Proposal: false,
      showWorkflowActions: true,
    };
  },
  components: {
    AuctionCarsPage,
    ToastMsg,
    ConfirmationDialog
  },
  computed: {
    selectedDate() {

    },
    watchAuction(val) {
      this.selectedAuctionType = val.selectedAuctionType;
      this.cars_information_unavailable = val.cars_information_unavailable;
      this.editable_by = val.editableBy
    },

  },
  watch: {
    watchAuction: function (val) {
      this.selectedAuctionType = val;
      this.cars_information_unavailable = val;
      this.editable_by = val;
    },
    workflow_state: function () {



      if (this.workflow_state != 'Draft') {
        setTimeout(() => {
          this.is_disabled = true;
        }, 300);
      }
      else {
        setTimeout(() => {
          this.is_disabled = false;
        }, 300);
      }
    }
  },
  computed: {
    selectedDate() {
      return this.$moment(this.date).format('dd-MM-yyyy');
    },
  },
  methods: {
    showLoadingIndicator(message,color) {
    const loadingIndicator = document.createElement('div');
    loadingIndicator.innerHTML = `<div class="spinner-border" role="status" style='color:${color}'></div><span style="margin-left:1rem;"><b style='font-size:14px;color:${color}'>${message}</b></span>`;
    loadingIndicator.style.position = 'fixed';
    loadingIndicator.style.top = '0';
    loadingIndicator.style.left = '0';
    loadingIndicator.style.width = '100%';
    loadingIndicator.style.height = '100%';
    loadingIndicator.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
    loadingIndicator.style.display = 'flex';
    loadingIndicator.style.justifyContent = 'center';
    loadingIndicator.style.alignItems = 'center';
    loadingIndicator.style.zIndex = '9999';
    loadingIndicator.classList.add('loading-indicator');
    document.body.appendChild(loadingIndicator);
},
removeLoadingIndicator() {
    const loadingIndicator = document.querySelector('.loading-indicator');
    if (loadingIndicator) {
        loadingIndicator.remove();
        this.$store.commit('incrementRefreshKey');
        this.$store.commit('incrementRefreshKey');
    }
},
    showConfirmationDialog() {
      return new Promise((resolve, reject) => {
        this.$refs.ConfirmationDialog.dialog = true;
        this.$refs.ConfirmationDialog.resolve = resolve;
        this.$refs.ConfirmationDialog.reject = reject;
      });
    },
    handleChildEvent(dataFromChild) {
      this.auction_items = dataFromChild;

    },
    data() {
      return {
        supplier: [],
        city: [],
        selectedSupplier: null,
        date: new Date(),
      };
    },
    reload() {
      this.$store.commit('incrementRefreshKey');

    },
    Print_format(event,selectedLanguage){
      console.log(selectedLanguage)
      fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.frappe_set_value`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "User",
                            name: this.$store.state.ERP_USER,
                            field:"print_format_language",
                            value: selectedLanguage,
                            sid: this.$store.state.SID
                          })
                        })
                    .then(response => response.json())
                    .then(language_changed => {
                            let name=this.auction_id
                            console.log("name",name)
                            if (name) {
                                        let url = `${this.$store.state.DATA_DOMAIN}/api/method/frappe.utils.print_format.download_pdf?doctype=Auction&name=${name}&format=Fleet%20Used%20Cars%20-%20Price%20proposal&no_letterhead=1&letterhead=No%20Letterhead&settings=%7B%7D&_lang=${selectedLanguage}&sid=${this.$store.state.SID}`;
                                        window.open(url, '_blank').focus();
                            }
                    })
                    .catch(error => {
                      console.error('Error clearing attachment:', error);
                    });
    },
    clear_attachment(event) {
      this.onChange(event)
      let query_params = new URLSearchParams(window.location.search);
      if (query_params.get('auction_id')) {
        const auctionId = query_params.get('auction_id');
        const apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.clear_attachment?auction_id=${auctionId}&sid=${this.$store.state.SID}&lang=${this.$store.state.LANGUAGE}`;

        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
          },
          body: JSON.stringify({
            auction_id: auctionId,
          }),
        })
          .then(response => response.json())
          .then(data => {
            
          })
          .catch(error => {
            console.error('Error clearing attachment:', error);
          });
      }
      this.file_url = null;
      this.auctionDocument = null;
    },
    get_auction_data() {

      if (this.auction_id === "new-auction") {
        return;
      }

      const apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_auction_data?auction_id=${this.auction_id}&sid=${this.$store.state.SID}&lang=${this.$store.state.LANGUAGE}`;;

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          const r = data.message[0];

          console.log("r",r)
          this.auction_id = r.name;
          this.selectedSupplier = r.supplier_name;
          this.selectedDate = r.date;
          this.selectedCity = r.city_name;
          this.selectedAuctionType = r.type;
          this.number_of_cars = r.number_of_cars;
          this.location = r.location;
          this.carsInformationUnavailable = r.cars_information_unavailable;
          this.file_url = r.auction_document;
          this.file_name = r.auction_document_file_name;
          this.workflow_state = r.workflow_state;



        })
        .catch(error => {
          console.error('Error fetching auction data:', error);
        });
    },

    async get_workflow_actions() {
      if (this.auction_id == "new-auction") {
        return;
      }
      const vm = this;

      let query_params = new URLSearchParams(window.location.search);
      if (this.auction_id != "new-auction") {
        const auctionId = this.auction_id;
        const apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_workflow_actions?auction_id=${auctionId}&sid=${this.$store.state.SID}&lang=${this.$store.state.LANGUAGE}`;
        let get_workflow_actions = await fetch(apiUrl)
        
        let data  = await get_workflow_actions.json()

        let workflowActions = data.message[0];
        let editableBy = data.message[1];
        console.log("workflowActions", workflowActions);
        console.log("editableBy", editableBy);
        const USER_ROLES = this.$store.state.USER_ROLES;

        let matchingActions = []; // Initialize an array to store matching actions

          for (let i = 0; i < workflowActions.length; i++) {
              let allowedRoles = workflowActions[i].allowed.split(',').map(role => role.trim());
              // Split the allowed roles string into an array and trim whitespace from each role
              if (allowedRoles.some(role => USER_ROLES.includes(role))) {
                  console.log("workflowActions[i].allowed", workflowActions[i].allowed);
                  matchingActions.push(workflowActions[i]); // Append matching action to the array
              }
          }

          // Now matchingActions array contains all actions that match the condition
          // You can use it as needed, for example:
          vm.workflow_actions = matchingActions;
         
          vm.editable_by = editableBy;
     

        vm.dialog = false;
        
      }


    },

    isRedMakeYear(index) {
      return this.redMakeYearIndexes.includes(index);
    },


    isRedOdometer(index) {
      return this.redOdometerIndexes.includes(index);
    },


    async set_workflow_action(event, item) {
      this.event_workflow = event;
      this.item_workflow = item;
      this.auction_options = []
      const vm = this;
      vm.is_needed_action = false


      if (this.auction_id == "new-auction") {
        return;
      }

      
      const workflow_state = event.target.innerText;

      if (workflow_state == "Send To Team Leader"){

        console.log("workflow_stateee",workflow_state)
        let cars = []
        let index = 0



        let errors = [];


        for (let i = 0; i < vm.auction_items.length; i++) {
          const item = vm.auction_items[i];




          if (vm.selectedAuctionType == "Auction" || (vm.selectedAuctionType == "Wholesale" && vm.carsInformationUnavailable == true)) {
            let string = ""
            if (item.make_id == null || item.make_id == "") {
              string = string + "Make, "
            }
            if (item.model_id == null || item.model_id == "") {
              string = string + "Model, "
            }
            if ((item.trim_id == null || item.trim_id == "") && item.is_trim == true) {
              string = string + "Trim, "
            }
            if ((item.engine_size == null || item.engine_size == "")) {
              string = string + "Engine, "
            }
            if ((item.fuel == null || item.fuel == "")) {
              string = string + "fuel, "
            }
            if (item.make_year == null || item.make_year == "") {
              string = string + "Make Year, "
            }
            if (item.plate == null || item.plate == " -   ") {
              string = string + "Plate, "
            }
            if (vm.selectedDate == null || vm.selectedDate == "") {
              string = string + "Auction Date, "
            }
            if (string !== "") {
              errors.push(`Row ${i + 1}`);
            }

          }

          if (item.inspect == 0) {
            continue;
          }


          const plate = item.plate;

        
            index++;
        }
        if (errors.length > 0) {
          const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }
          const errorMsg = 'Required Information is missing for:<br>Auction:<br>' + (errors.join(', ')) +
          '<hr><br>Please note that the following fields are required for all rows: Make, Model, Trim,engine,fuel, Make Year, Plate';
          let toast_comp = <ToastMsg msg={errorMsg} />
          this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
          this.selected_item = [];
          return false;
        }

      }


      if (workflow_state == 'Send to inspection') {
        // console.log("this.selectedAuctionType ",this.selectedAuctionType )
        // console.log("this.carsInformationUnavailabl ",this.carsInformationUnavailable )
       
        if (this.selectedAuctionType == "Wholesale" && this.carsInformationUnavailable){ 
          
          // if (!vm.carsInformationUnavailable) {
          //     const errorMsg = '<h4>Please select Cars Information Unavailable</h4>';
          //     let toast_comp = <ToastMsg msg={errorMsg} />
          //     this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
          //     return             
          // }
          let number_of_rows = 0;
          let number_of_cars = this.number_of_cars || 0;
          this.$refs.cars_items.auction_items = [];
          for (let index = 0; index < number_of_rows; index++) {
            this.$refs.cars_items.addRow();
          }
          let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.add_wholesale_cars`;

          let args = {
            num_of_cars : number_of_cars,
            auction_id : this.auction_id,
            sid : this.$store.state.SID
          };

          await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
            },
            body: JSON.stringify(args),
          })
          .then(response => response.json())
          .then(data =>{
            if (data.message != "success"){
              const errorMsg = `<h4>${data._server_messages}</h4>`;
              let toast_comp = <ToastMsg msg={errorMsg} />
              this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })              
              return
            }
            else{
              fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "Auction",
                            docname: this.auction_id,
                            // field:"workflow_state",
                            // value: "Pending inspection",
                            action: "Send to inspection",
                            sid: this.$store.state.SID
                          })
                        })
                    .then(response => response.json())
                    .then(updateData => {
                      vm.workflow_actions.forEach(element => {
                        if (element.action == event.target.innerText) {
                          this.dialog = false;
                          vm.workflow_state = "Pending inspection";
                          fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "Auction",
                            docname: this.auction_id,
                            // field:"workflow_state",
                            // value: "Pending inspection",
                            action: workflow_state,
                            sid: this.$store.state.SID
                          })
                        })
                            .then(response => response.json())
                            .then(updateData => {
                              if (updateData.status && updateData.status.message === "Error in Update Workflow") {
                                    console.log("Error in Update Workflow:", updateData);
                                    const errorMsg = '<h4>' + updateData.message.replace("ValidationError", "") + '</h4>';
                                    let toast_comp = <ToastMsg msg={errorMsg} />
                                    this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })              
                                    return;
                                }
                              vm.get_workflow_actions();
                              
                            })
                            .catch(error => {
                              console.error('Error updating workflow state:', error);
                              const errorMsg = `<h4>Error updating workflow state : ${error}</h4>`;
                              let toast_comp = <ToastMsg msg={errorMsg} />
                              this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
                            });
                        }
                      });
                    })
                    .catch(error => {
                      console.error('Error updating workflow state:', error);
                      const errorMsg = `<h4>Error updating workflow state : ${error}</h4>`;
                      let toast_comp = <ToastMsg msg={errorMsg} />
                      this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
                    });
            }
          })
          
          return
        }


        for (const item of vm.auction_items) {
   
          if (item.plate_text_1 == "" || item.plate_text_2 == "" || item.plate_text_3  == "" || item.plate_number == "" || item.plate_number == 0) {
          let errorMsg = '<h4>The Plate Number Should Fill</h4>'
          let toast_comp = <ToastMsg msg={errorMsg} />
          this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })

          return false;

        }
        }
        this.showLoadingIndicator("Processing Data","#0089FF")
        this.header_name = "Cars Need Action"

        console.log("workflow_state","element.action")

        if (!vm.auction_items || vm.auction_items.length === 0) {

          vm.workflow_actions.forEach(element => {
            if (element.action == event.target.innerText) {
              this.dialog = false;
              vm.workflow_state = element.next_state;

              console.log("workflow_state",element.action)
              fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN

            },
            body: JSON.stringify({
              doctype: "Auction",
              docname: this.auction_id,
              // field:"workflow_state",
              // value: element.next_state,
              action:element.action,
              sid: this.$store.state.SID

            }),
          })
            .then(response => response.json())
            .then(data => {
              console.log("data send to inspect",data)


                this.get_workflow_actions();
                this.reload()

            })
            .catch(error => {
              console.error('Error fetching data:', error);
            });
            }
          });
        }


        let cars = []
        let index = 0



        let errors = [];


        for (let i = 0; i < vm.auction_items.length; i++) {
          const item = vm.auction_items[i];




          if (vm.selectedAuctionType == "Auction" || (vm.selectedAuctionType == "Wholesale" && vm.carsInformationUnavailable == true)) {
            let string = ""
            if (item.make_id == null || item.make_id == "") {
              string = string + "Make, "
            }
            if (item.model_id == null || item.model_id == "") {
              string = string + "Model, "
            }
            if ((item.trim_id == null || item.trim_id == "") && item.is_trim == true) {
              string = string + "Trim, "
            }
            if ((item.engine_size == null || item.engine_size == "")) {
              string = string + "Engine, "
            }
            if ((item.fuel == null || item.fuel == "")) {
              string = string + "fuel, "
            }
            if (item.make_year == null || item.make_year == "") {
              string = string + "Make Year, "
            }
            if (item.plate == null || item.plate == " -   ") {
              string = string + "Plate, "
            }
            if (vm.selectedDate == null || vm.selectedDate == "") {
              string = string + "Auction Date, "
            }
            if (string !== "") {
              errors.push(`Row ${i + 1}`);
            }

          }

          if (item.inspect == 0) {
            continue;
          }


          const plate = item.plate;

          let carsitem = {}
          if (plate) {
            carsitem = {
              name: item.name,
              make_id: item.make,
              model_id: item.model,
              trim_id: item.trim,
              make_year: item.make_year,
              color_id: item.color,
              fuel: item.fuel,
              engine_size: item.engine_size,
              odometer: item.odometer,
              plate: plate,
              origin: item.origin_name,
              plate_number: item.plate_number,
              plate_text_1: item.plate_text_1,
              plate_text_2: item.plate_text_2,
              plate_text_3: item.plate_text_3,

            }

            let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.check_car_info?sid=${this.$store.state.SID}
            &plate=${plate}
            &plate_number=${item.plate_number}
            &plate_text_1=${item.plate_text_1}
            &plate_text_2=${item.plate_text_2}
            &plate_text_3=${item.plate_text_3}
            &auction=${vm.auction_id}&purchase_oppurtunity_id=${item.name}`;

            await fetch(apiUrl, {
              method: 'POST',
              async: false,
              headers: {
                'Content-Type': 'application/json',
                'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
              },
            })
              .then(response => response.json())
              .then(data => {

                if (data.message[0]) {


                  if (data.message[1]) {

                    carsitem.inspection = data.message[1];
                  }
                  if (data.message[0]) {

                    carsitem.status = data.message[0];
                    if (data.message[2] == "1") {
                      Vue.set(carsitem, "auction_options", [{ id: data.message[3], name: data.message[3] }]);
                      Vue.set(carsitem, "auction_value", data.message[3]);
                    } else {
                      Vue.set(carsitem, "auction_options", [{ id: "", name: "" }, { id: data.message[3][0], name: data.message[3][0] }, { id: data.message[3][1], name: data.message[3][1] }]);
                      Vue.set(carsitem, "auction_value", "");
                    }
                  }
                  this.is_needed_action = true;
                  
                  cars.push(carsitem);
                }

                this.selected_item = cars;
                console.log("select item",this.selected_item)

              })


            }
            index++;
        }
        if (errors.length > 0) {
          const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }
          const errorMsg = 'Required information is missing for:<br>Auction:<br>' + (errors.join(', ')) +
          '<hr><br>Please note that the following fields are required for all rows: Make, Model, Trim, engine, fuel, Make Year, Plate';
          let toast_comp = <ToastMsg msg={errorMsg} />
          this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
          this.selected_item = [];
          return false;
        }
        setTimeout(() => {



          if (vm.is_needed_action) {


            this.dialog = true;
            const loadingIndicator = document.querySelector('.loading-indicator');
            if (loadingIndicator) {
                loadingIndicator.remove();
            }
          }
          else {



            setTimeout(() => {


              
                if (vm.auction_items) {
                  for (const item of vm.auction_items) {
                    let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.change_opportunity_status?sid=${this.$store.state.SID}
                    &opportunity=${item.name}&is_inspected=${item.inspect}`;


                    fetch(apiUrl, {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    })
                    .then(response => response.json())
                      .then(data => {

                        vm.workflow_actions.forEach(element => {
                          if (element.action == event.target.innerText) {
                            // vm.workflow_state = element.next_state;

                           
                            // fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                            //   method: 'POST',
                            //   headers: {
                            //     'Content-Type': 'application/json',
                            //     'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN

                            //   },
                            //   body: JSON.stringify({
                            //     doctype: "Auction",
                            //     docname: this.auction_id,
                            //     // field:"workflow_state",
                            //     // value: element.next_state,
                            //     action: element.action,
                            //     sid: this.$store.state.SID
                            //   })
                            // })
                            // .then(response => response.json())
                              // .then(data => {
                              //   console.log("move_workflow4")
                              //   console.log("dddddata Send to inspection else",data)

                                // if (data.status && data.status.message === "Error in Update Workflow") {
                                //     console.log("Error in Update Workflow:", data);
                                //     const errorMsg = '<h4>' + data.message.replace("ValidationError", "") + '</h4>';
                                //     let toast_comp = <ToastMsg msg={errorMsg} />
                                //     this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })              
                                //     return;
                                // }

                              // })
                              vm.workflow_state = element.next_state;
                              this.get_workflow_actions();
                              this.reload();
                      
                          }
                        });
                      })
                      .catch(error => {
                        console.error('Error fetching data:', error);
                      });

                  }
                }
                else {

                  if (vm.workflow_actions == 'Send to inspection') {
                    workflow_actions.forEach(element => {
                      this.dialog = false;
                      vm.$parent.workflow_state = element.next_state;
                      console.log("element.next_state",element.next_state)


                      fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN

                        },
                        body: JSON.stringify({
                          doctype: "Auction",
                          docname: this.auction_id,
                          // field:"workflow_state",
                          // value: element.next_state,
                          action: "Send to inspection",
                          sid: this.$store.state.SID

                        }),
                      })
                        .then(response => response.json())
                        .then(data => {
                          console.log("data Send to inspection manual",data)
                          if (data.status && data.status.message === "Error in Update Workflow") {
                                    console.log("Error in Update Workflow:", data);
                                    const errorMsg = '<h4>' + data.message.replace("ValidationError", "") + '</h4>';
                                    let toast_comp = <ToastMsg msg={errorMsg} />
                                    this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })              
                                    return;
                                }

                            this.$parent.get_workflow_actions();

                        })
                        .catch(error => {
                          console.error('Error fetching data:', error);
                        });
                    });
                  
                  }
                }
                const loadingIndicator = document.querySelector('.loading-indicator');
                if (loadingIndicator) {
                    loadingIndicator.remove();
                    // this.reload();
                }
            },

              300);

          }
        },

          500);

      }
      else if (workflow_state == "Proceed") {

console.log("worrrrrkflow_state",workflow_state)

        vm.auction_items = this.$refs.cars_items.auction_items;
        for (let i = 0; i < vm.auction_items.length; i++) {
          console.log("vm.auction_items[i]",vm.auction_items[i])
          const item = vm.auction_items[i];
          if (item.result == 0) {
            const errorMsg = '<h4>Please select a result for all opportunities.</h4>';
            let toast_comp = <ToastMsg msg={errorMsg} />
            this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
            return
          }

        }
        const apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.frappe_get_list?sid=${this.$store.state.SID}&doctype=Purchase Opportunity&filters=[["Purchase Opportunity","auction","=","${vm.auction_id}"]]&fields=["name","workflow_state"]`;

        fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          // body: JSON.stringify({
          //                 doctype: "Purchase Opportunity",
          //                 filters:{ auction: vm.auction_id },
          //                 fields: ["name", "workflow_state"],
          //                 sid: this.$store.state.SID
          //               }),
        })
          .then(response => response.json())
          .then(data => {

            let valedation_comp = false;
            for (const item of data.message) {

              if (item.workflow_state === "Won") {

                valedation_comp = true;
              }
            }


            if (valedation_comp == true) {
              vm.workflow_actions.forEach(element => {
                if (element.action == event.target.innerText) {
                  this.dialog = false;
                  vm.workflow_state = element.next_state;
                  fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "Auction",
                            docname: this.auction_id,
                            // field:"workflow_state",
                            // value: element.next_state,
                            action: element.action,
                            sid: this.$store.state.SID
                          })
                        })
                    .then(response => response.json())
                    .then(updateData => {
                      console.log("updateData",updateData)
                      vm.get_workflow_actions();
                      vm.reload();
                    })
                    .catch(error => {
                      console.error('Error updating workflow state:', error);
                    });
                }
              });
            } else {
              vm.workflow_actions.forEach(element => {
                if (element.action == event.target.innerText) {
                  this.dialog = false;
                  vm.workflow_state = element.next_state;
                  fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "Auction",
                            docname: this.auction_id,
                            // field:"workflow_state",
                            // value: "Completed",
                            action: "Completed",
                            sid: this.$store.state.SID
                          })
                        })
                    .then(response => response.json())
                    .then(updateData => {
                      vm.workflow_actions.forEach(element => {
                        if (element.action == event.target.innerText) {
                          this.dialog = false;
                          vm.workflow_state = "Completed";
                          fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "Auction",
                            docname: this.auction_id,
                            // field:"workflow_state",
                            // value: "Completed",
                            action: "Completed",
                            sid: this.$store.state.SID
                          })
                        })
                            .then(response => response.json())
                            .then(updateData => {
                              vm.get_workflow_actions();
                              vm.reload();
                            })
                            .catch(error => {
                              console.error('Error updating workflow state:', error);
                            });
                        }
                      });
                    })
                    .catch(error => {
                      console.error('Error updating workflow state:', error);
                    });
                }
              });
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });




      }
      else if (workflow_state == "Send for approval" || workflow_state == "Approve") {

        for (let i = 0; i < vm.auction_items.length; i++) {
          const item = vm.auction_items[i];

          if ((item.inspect == 1 && item.action == "" ) || (item.action == "Approved" && item.estimated_selling == 0) ) {
            const errorMsg = '<h4>There are opportunities with no action selected</h4>';
            let toast_comp = <ToastMsg msg={errorMsg} />
            this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
            return
          }

        }
        if (vm.workflow_state == "Price Estimation") {
          vm.workflow_actions.forEach(element => {
            if (element.action == event.target.innerText) {
              this.dialog = false;
              vm.workflow_state = element.next_state;
              console.log("action",element.action)
              fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "Auction",
                            docname: this.auction_id,
                            // field:"workflow_state",
                            // value: element.next_state,
                            action: element.action,
                            sid: this.$store.state.SID
                          })
                        })
                .then(response => response.json())
                .then(updateData => {
                  console.log("updateData",updateData)
                  this.get_workflow_actions();
                  this.reload();
                })
                .catch(error => {
                  console.error('Error updating workflow state:', error);
                });

            }
          });
        }
        else if (vm.workflow_state == "Price Proposal(Pending Approval)") {
          vm.cahnge_workflow_Price_Proposal = true
          setTimeout(() => {
            vm.save()
            let valedation_proposal = false
            let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.frappe_get_list?sid=${this.$store.state.SID}&doctype=Purchase Opportunity&filters=[["Purchase Opportunity","auction","=","${vm.auction_id}"]]&fields=["name","workflow_state","action","inspect"]`;

        fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
          // body: JSON.stringify({
          //                 doctype: "Purchase Opportunity",
          //                 filters:{ auction: vm.auction_id },
          //                 fields: ["name", "workflow_state", "action", "inspect"],
          //                 sid: this.$store.state.SID
          //               }),
        })
              .then(response => response.json())
              .then(data => {

                console.log(" Price Proposal(Pending Approval)data", data)
                if (data.message.some(item => item.action === "Approved" && item.inspect === 1)) {
                  console.log(" Price Proposal(Pending Approval)true")
                  valedation_proposal = true;
                }


                if (valedation_proposal == true) {
                  vm.workflow_actions.forEach(element => {
                    if (element.action == event.target.innerText) {
                      this.dialog = false;
                      vm.workflow_state = element.next_state;
                      fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "Auction",
                            docname: this.auction_id,
                            // field:"workflow_state",
                            // value: element.next_state,
                            action: element.action,
                            sid: this.$store.state.SID
                          })
                        })
                        .then(response => response.json())
                        .then(updateData => {
                          this.get_workflow_actions();
                          this.reload();
                        })
                        .catch(error => {
                          console.error('Error updating workflow state:', error);
                        });
                    }
                  });
                } else {
                  vm.workflow_actions.forEach(element => {
                    if (element.action == event.target.innerText) {
                      this.dialog = false;
                      vm.workflow_state = "Completed";
                      fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "Auction",
                            docname: this.auction_id,
                            // field:"workflow_state",
                            // value: "Completed",
                            action: element.action,
                            sid: this.$store.state.SID
                          })
                        })
                        .then(response => response.json())
                        .then(updateData => {
                          this.get_workflow_actions();
                          this.reload();
                        })
                        .catch(error => {
                          console.error('Error updating workflow state:', error);
                        });
                    }
                  });
                }
              })
              .catch(error => {
                console.error('Error fetching data:', error);
              });

          }, 100);


        }
      }
      else {
        let is_ok = true;
        if (workflow_state == 'Start Inspection') {
          this.showLoadingIndicator("Processing Data","#0089FF")
          console.log("Reached Start Inspection")
          let auction_items = this.$refs.cars_items.auction_items;
          for (const itm of auction_items) {

            this.purchases.push({ "purchase_id": itm.name, "assignee_user": itm.user || "" });
          }
          this.$set(this.assigneeDict, 'auction_id', this.auction_id);
          this.$set(this.assigneeDict, 'purchases', this.purchases);
          let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.create_cars_inspection`;

          let args = {
            assigneeDict: this.assigneeDict,
            sid: this.$store.state.SID
          };

          await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN

            },
            body: JSON.stringify(args),
          })
            .then(response => response.json())
            .then(data => {

              if (data.message == "All Opportunities must be assigned") {
                const errorMsg = '<h4>All Opportunities must be assigned</h4>';
                let toast_comp = <ToastMsg msg={errorMsg} />
                this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
                is_ok = false;

                this.reload();
              }
              this.removeLoadingIndicator()
            })
            .catch(error => {
              console.error('Error creating cars inspection:', error);
            });

        }
        else if (workflow_state == 'Complete Inspection') {
          let auction_items = this.$refs.cars_items.auction_items;
          let is_in_pending = false
          let is_in_progress = false
          let purchase_list = []
          for (const itm of auction_items) {
            console.log("itm", itm)
            if (itm.po_status == "In Progress") is_in_progress = true

            if (itm.po_status == "Pending") {
              is_in_pending = true 
            }
            else{
              is_in_pending = false
            }
            purchase_list.push({ "name": itm.name, "is_in_pending": is_in_pending })
          }
          if (is_in_progress) {
            const errorMsg = '<h4>Inspections are still in progress</h4>';
            let toast_comp = <ToastMsg msg={errorMsg} />
            this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
            return
          }
          if (is_in_pending) {
            let ConfirmationDialogResult = await this.showConfirmationDialog();
            console.log("purchase_list", purchase_list)
            if (ConfirmationDialogResult) {
                purchase_list=JSON.stringify(purchase_list)
                let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.update_purchase_and_cars_inspections`;


                  let args = {
                    purchase_list: purchase_list,
                    sid: this.$store.state.SID
                  };

                  fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN

                    },
                    body: JSON.stringify(args),
                  })

                  .then(response => response.json())
                  .then(data => {
                    console.log("data purchase_list", data)
                    vm.workflow_actions.forEach(element => {
                      if (element.action == event.target.innerText) {
                        vm.workflow_state = element.next_state;
                        fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "Auction",
                            docname: this.auction_id,
                            // field:"workflow_state",
                            // value: element.next_state,
                            action: element.action,
                            sid: this.$store.state.SID
                          })
                        })
                          .then(response => response.json())
                          .then(updateData => {
                            vm.get_workflow_actions();
                            this.reload();
                          })
                          .catch(error => {
                            console.error('Error updating workflow state:', error);
                          });
                      }
                    });
                  })
                  .catch(error => {
                    console.error('Error updating purchase and cars inspections:', error);
                  });

              } else {
                return
              }
            return
          }
          else {
            purchase_list=JSON.stringify(purchase_list)
            console.log("purchase_list111", purchase_list)
            let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.update_purchase_and_cars_inspections`;

                let args = {
                    purchase_list: purchase_list,
                    sid: this.$store.state.SID
                  };

                  fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN

                    },
                    body: JSON.stringify(args),
                  })
              .then(response => response.json())
              .then(data => {
                vm.workflow_actions.forEach(element => {
                  if (element.action == event.target.innerText) {
                    vm.workflow_state = element.next_state;
                    fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                      },
                      body: JSON.stringify({
                        doctype: "Auction",
                        docname: this.auction_id,
                        // field:"workflow_state",
                        // value: element.next_state,
                        action: element.action,
                        sid: this.$store.state.SID
                      })
                    })
                      .then(response => response.json())
                      .then(updateData => {
                        vm.get_workflow_actions();
                        this.reload();
                      })
                      .catch(error => {
                        console.error('Error updating workflow state:', error);
                      });
                  }
                });
              })
              .catch(error => {
                console.error('Error updating purchase and cars inspections:', error);
              });

            return
          }
        }
        else if (this.workflow_state == "Cars Info Validation") {
          let auction_items = vm.$refs.cars_items.auction_items;
          if (auction_items.length <= 0) {
            const errorMsg = '<h4>Please add cars to the auction</h4>';
            let toast_comp = <ToastMsg msg={errorMsg} />
            this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
            return
          }
          let purchase_list = []
          let vin_list = []
          for (let item of auction_items) {
            if (!item.purchase_request) {
              purchase_list.push("New Request")
            }
            else {
              purchase_list.push(item.purchase_request)
            }

            console.log("iiiiiiitem", item)
            if (!item.make_id || !item.model_id || !item.color_id || !item.fuel || !item.engine_size  || !item.plate_number || !item.odometer || !item.plate_text_1 || !item.plate_text_2 || !item.plate_text_3 || !item.make_year || (!item.is_vin && item.vin == "")) {
              const errorMsg = '<h4>All Fields are required</h4>';
              let toast_comp = <ToastMsg msg={errorMsg} />
              this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
              return
            }
            if (item.is_vin == false) {
           
              vin_list.push(item.vin)
            }

            console.log("vin_list", vin_list)

            if (!item.trim_id && item.car_trim_list.length > 0) {
              const errorMsg = '<h4>Please select a trim</h4>';
              let toast_comp = <ToastMsg msg={errorMsg} />
              this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
              return
            }

          }

          let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.check_purchase_and_vin?sid=${this.$store.state.SID}&purchase_list=${JSON.stringify(purchase_list)}&vin_list=${JSON.stringify(vin_list)}`;

          fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',

            }
          })
            .then(response => response.json())
            .then(data => {
              console.log("dataaaaa",data)
              if(data.exception){
                const errorMsg = data.exception.replace("frappe.exceptions.ValidationError:", "")
                let toast_comp = <ToastMsg msg={errorMsg} />
                this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
                return

              }
              if (data.message == "True" || data.message == true) {
                vm.workflow_actions.forEach(element => {
                  if (element.action == event.target.innerText) {
                    vm.workflow_state = element.next_state;
                    fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                          },
                          body: JSON.stringify({
                            doctype: "Auction",
                            docname: this.auction_id,
                            action: element.action,
                            sid: this.$store.state.SID
                          })
                        })
                      .then(response => response.json())
                      .then(updateData => {
                        vm.get_workflow_actions();
                        this.reload();
                      })
                      .catch(error => {
                        console.error('Error updating workflow state:', error);
                      });
                  }
                });
              }
            })
            .catch(error => {
              console.error('Error checking purchase and VIN:', error);
            });


          return
        }
        setTimeout(() => {
          if (is_ok) {

            vm.workflow_actions.forEach(element => {
              if (element.action == event.target.innerText) {
                this.dialog = false;
                vm.workflow_state = element.next_state;

                fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.move_auction_workflow`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                  },
                  body: JSON.stringify({
                  doctype: "Auction",
                  docname: this.auction_id,
                  // field:"workflow_state",
                  // value: element.next_state,
                  action: element.action,
                  sid: this.$store.state.SID

            }),
                })
                  .then(response => response.json())
                  .then(updateData => {
                    vm.get_workflow_actions();
                    this.reload();
                  })
                  .catch(error => {
                    console.error('Error updating workflow state:', error);
                  });

              }
            });
          }
        }, 100);
      }
      ;

      if (vm.workflow_actions == 'Send to inspection') {
        console.log("workflowwww_actions", workflow_actions)
        workflow_actions.forEach(element => {
          this.dialog = false;
          vm.$parent.workflow_state = element.next_state;
          fetch(`${this.$store.state.DATA_DOMAIN}/api/resource/Auction/${this.auction_id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'sid': this.$store.state.SID,
              'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
            },
            body: JSON.stringify({
              workflow_state: element.next_state
            })
          })
            .then(response => response.json())
            .then(updateData => {
              this.$parent.get_workflow_actions();
              const loadingIndicator = document.querySelector('.loading-indicator');
                if (loadingIndicator) {
                    loadingIndicator.remove();
                    // this.reload()

                    
                   
                }
            })
            
            .catch(error => {
              console.error('Error updating workflow state:', error);
            });

        });
        // this.reload();
      }

    },
    onSelectedAuctionType(event){
      this.onChange(event)
    },
    async onImportFileChange(event) {
      const vm = this;
      let file_data = event.target.files[0];
      this.importFile = file_data;
      let file = new FormData();
      if (file_data) {
            this.showLoadingIndicator("Uploading File","#0089FF")
            file.append('file', file_data);
            file.append('sid',this.$store.state.SID)
            try {
              const fileResponse = await fetch(`${this.$store.state.DATA_DOMAIN}/api/method/upload_file`, {
                method: 'POST',
                headers: {
                  'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                },
                body: file
              });
              const fileData = await fileResponse.json();

              this.file_url = fileData.message.file_url;
              this.file_name = fileData.message.file_name;

              await fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.import_data`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
                },
                body: JSON.stringify({
                  sid: this.$store.state.SID,
                  file_name: fileData.message.name,
                  auction: this.auction_id,
                }),
              }).then(response => {
                if (response.status != 200) {
                  const errorMsg = '<h4>Error uploading file : ' + response.statusText + '</h4>';
                  let toast_comp = <ToastMsg msg={errorMsg} />
                  this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
                  return
                }
                else{
                  return response.json()  
                }
                })
                .then(data => {
                  // remove loading indicator
                  const loadingIndicator = document.querySelector('.loading-indicator');
                  if (loadingIndicator) {
                      loadingIndicator.remove();
                  }
                  let data_msg = data.message
                  if (data_msg){
                    this.$toast.success('Data imported successfully : ', { position:"top-center", duration: 10000 })
                    vm.reload();
                  }
                  else{
                    this.$toast.error('Error importing data : Data is Empty', { position:"top-center", duration: 10000 })
                  }
                })
                .catch(error => {
                  const loadingIndicator = document.querySelector('.loading-indicator');
                  if (loadingIndicator) {
                      loadingIndicator.remove();
                  }
                  // reload cars component
                  vm.reload();
                  console.error('Error:', error);
                });
              
              
              
            } 
            catch (error) {
              const loadingIndicator = document.querySelector('.loading-indicator');
                  if (loadingIndicator) {
                      loadingIndicator.remove();
                  }
              const errorMsg = '<h4>Error uploading file : ' + error + '</h4>';
              let toast_comp = <ToastMsg msg={errorMsg} />
              this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
              return
            }

      }
    
    },
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
          this.isSelecting = false
      }, { once: true });
      
      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    downloadTemplate(){
      const vm = this;
      let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.download_cars_template?sid=${this.$store.state.SID}`;
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response.status != 200) {
            const errorMsg = '<h4>Error downloading file : ' + response.statusText + '</h4>';
            let toast_comp = <ToastMsg msg={errorMsg} />
            this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
            return
          }
          else{
            return response.json()
        }
        })
        .then(data => {
          let url = data.message
          window.open(url, '_blank');

        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    onCarsInfoClicked(event){
      const vm = this;
      if (this.carsInformationUnavailable || this.workflow_state!="Draft") {
        this.addRowBtnDisabled = true;
      }
      else{
        this.addRowBtnDisabled = false;
      }
      if (!vm.carsInformationUnavailable) return;
  
      let number_of_rows = 0;
      this.number_of_cars = 0;
      this.$refs.cars_items.auction_items = [];
      for (let index = 0; index < number_of_rows; index++) {
        this.$refs.cars_items.addRow();
      }
      vm.onChange(event)
    },
    async onChange(event) {
      const vm = this;


      let file_data = this.auctionDocument;
      let file = new FormData();
      if (file_data) {
        this.showLoadingIndicator("Uploading File","#0089FF")
        file.append('file', file_data);
        file.append('sid',this.$store.state.SID)
        try {
    const fileResponse = await fetch(`${this.$store.state.DATA_DOMAIN}/api/method/upload_file`, {
      method: 'POST',
      headers: {
        'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
      },
      body: file
    });
    const fileData = await fileResponse.json();

    this.file_url = fileData.message.file_url;
    this.file_name = fileData.message.file_name;
    console.log("data.message.file_url", fileData.message);

    const updatedDocResponse = await fetch(`${this.$store.state.DATA_DOMAIN}/api/resource/Auction/${this.auction_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
      },
      body: JSON.stringify({
        sid: this.$store.state.SID,
        auction_document: fileData.message.file_url
      }),
    });
    const updatedDoc = await updatedDocResponse.json();

    console.log('Document updated successfully:', updatedDoc);
  } catch (error) {
    console.error('Error:', error);
  }

      }
      const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }


      this.changedKeys = true;
    },
    get_pos_setting() {

    },
    onKeyPress(event) {
      // Prevent non-numeric characters from being entered
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    
    Has_Access( doctype, permissionType) {
      const doctypePermissions = this.$store.state.DOCTYPE_PERMISSIONS
    if (doctypePermissions.hasOwnProperty(doctype)) {
            return doctypePermissions[doctype].some(permission => permission[permissionType] === 1);
        }
        return false;
    },
    async save() {
      console.log('save reached')
      this.showLoadingIndicator("Saving Data","#0089FF")
      try {

      this.purchases = []
      this.assigneeDict = {}

      let Validate_Number = !isNaN(parseFloat(this.number_of_cars)) && !isNaN(this.number_of_cars - 0)
      // if (this.selectedAuctionType == "Wholesale" && this.carsInformationUnavailable && this.number_of_cars < 1 ) {
      //   let errorMsg = '<h4>The Number Of Cars Should Be A Number Not A Text</h4>';
      //   let toast_comp = <ToastMsg msg={errorMsg} />
      //   this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
      //   return;
      // }
      console.log("this.selectedAuctionType",this.selectedAuctionType)
      console.log("this.carsInformationUnavailable",this.carsInformationUnavailable)
      console.log("this.number_of_cars",this.number_of_cars)
      console.log("Validate_Number",Validate_Number)
      if (this.carsInformationUnavailable == true)
      {
        if ((this.selectedAuctionType == "Wholesale"  ) && this.number_of_cars < 1) {
          let errorMsg = '<h4>The Number Of Cars Should Be At Least 1</h4>';
          let toast_comp = <ToastMsg msg={errorMsg} />
          this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
          const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }
          return;
        }
    }
      var reqd_fields = ""
      if (!this.selectedSupplier) {
        reqd_fields += "Supplier Name <br>"
      }
      if (!this.selectedDate) {
        reqd_fields += "Auction Date <br>"
      }
      if (!this.selectedCity) {
        reqd_fields += "Auction City <br>"
      }
      if (!this.selectedAuctionType) {
        reqd_fields += "Auction Type <br>"
      }
      if (reqd_fields != "") {
        let errorMsg = 'Please Enter Required Fields <br> <b>' + reqd_fields;
        let toast_comp = <ToastMsg msg={errorMsg} />
        this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
        const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }
        return;
      }
      const auctionCom = this.$refs.cars_items;
      const paltes_input = auctionCom['$refs']
      let auction_items = this.$refs.cars_items.auction_items;

      let plates_list = []

      let plate_string = ""
      let idx = 0
      let counter = 1
      let input_counter = 1
      for (const itm of auction_items) {
        this.purchases.push({ "purchase_id": itm.name, "assignee_user": itm.user || "" });
        for (const [key, value] of Object.entries(paltes_input)) {
          if (!value) {
            continue;
          }
          const extractSubstring = (str) => {
            const startIndex = str.indexOf('-') + 1;
            return str.substring(startIndex).trim();
          };

          const extractNumber = (str) => {
            const match = str.match(/\d+/);
            return match ? match[0] : null;
          };

          const number = extractNumber(key);
          const substring = extractSubstring(key);
          if (number == itm["id"] || number == itm["index"]) {


            if (substring == "input4") {
              itm["plate_number"] = value.$refs.input._value
              continue;
            }
            if (substring == "input3") {
              itm["plate_text_3"] = value.$refs.input._value
              continue;
            }
            if (substring == "input2") {
              itm["plate_text_2"] = value.$refs.input._value
              continue;
            }
            if (substring == "input1") {
              itm["plate_text_1"] = value.$refs.input._value
              continue;
            }



          }
        }
        idx++
      }




      let auction_items_list = []

      let plate_numbers_list = []
      for (let i = 0; i < auction_items.length; i++) {
        const item = auction_items[i];
        const auctionCom = this.$refs.cars_items;

        let action_list_status = ""
        if(!this.is_disabled){
          item['plate_text_1'] = auctionCom.$refs[i + '-input1'].$refs.input._value;
          item['plate_text_2'] = auctionCom.$refs[i + '-input2'].$refs.input._value;
          item['plate_text_3'] = auctionCom.$refs[i + '-input3'].$refs.input._value;
          item['plate_number'] = auctionCom.$refs[i + '-input4'].$refs.input._value;
          if (item['plate_text_1'] && item['plate_text_2'] && item['plate_text_3'] && item["plate_number"]) {
            plate_numbers_list.push((item['plate_text_1'] + " " + item['plate_text_2'] + " " + item['plate_text_3'] + " - " + item["plate_number"]))
          }

        }

        if  (this.carsInformationUnavailable == false && this.selectedAuctionType != "Wholesale" && this.workflow_state != "Draft" ){

                  
              if (item['plate_text_1'] == "" || item['plate_text_2']  == "" || item['plate_text_3']  == "" || item["plate_number"] == "" || item["plate_number"] == 0) {
                let errorMsg = '<h4>The Plate Number Should Fill</h4>'
                let toast_comp = <ToastMsg msg={errorMsg} />
                this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
                
                const loadingIndicator = document.querySelector('.loading-indicator');
                if (loadingIndicator) {
                    loadingIndicator.remove();
                }
                return;

              }
        }


     


        let validate = false
        if (item["make_id"]) {
          item.make = item["make_id"]
        }
        if (item["model_id"]) {
          item.model = item["model_id"]
        }
        if (item["trim_id"]) {
          item.trim = item["trim_id"]
        }
        if (item["color_id"]) {
          item.color = item["color_id"]
        }
        if (item["make_year"]) {
          item.make_year = item["make_year"]
        }
        if (item["odometer"]) {
          item.odometer = item["odometer"]
        }
        if (item["origin"]) {
          item.origin = item["origin"]
        }
        // if (item["item.number_of_cabins"]) {
        //   item.number_of_cabins = item["item.number_of_cabins"]
        // }
        if (item["item.engine_size"]) {
          item.engine_size = item["item.engine_size"]
        }
        if (item["item.cylinders_number"]) {
          item.cylinders_number = item["item.cylinders_number"]
        }
        if (item["plate_text_1"]) {
          item.plate_text_1 = item["plate_text_1"]
        }
        if (item["plate_text_2"]) {
          item.plate_text_2 = item["plate_text_2"]
        }
        if (item["plate_text_3"]) {
          item.plate_text_3 = item["plate_text_3"]
        }
        if (item["plate_number"]) {
          item.plate_number = item["plate_number"]

        }
        if (item["inspect"]) {
          item.inspect = item["inspect"]
        }
        if (item["user"]) {
          item.user = item["user"]
        }
        if (item['recommendation']) {
          item.recommendation = item['recommendation']
        }
        if (item['estimated_cost']) {
          item.estimated_cost = item['estimated_cost']
        }
        if (item['estimated_costs']) {
          item.estimated_costs = item['estimated_costs']
        }
        if (item['proposed_price']) {
          item.proposed_price = item['proposed_price']
        }
        if (item['total_proposed_price_without_tax']) {
          item.total_proposed_price_without_tax = item['total_proposed_price_without_tax']
        }
        if (item['added_cost']) {
          item.added_cost = item['added_cost']
        }
        if (item['estimated_selling']) {
          item.estimated_selling = item['estimated_selling']
        }
        if (item['profit_']) {
          item.profit_ = item['profit_']
        }
        if (item['result']) {
          item.result = item['result']
        }
        if(item['fuel']){
          item.fuel = item['fuel']
        }
        if(item['drivetrain']){
          item.drivetrain = item['drivetrain']
        }

        if (item['action'] == "Approved") {
          action_list_status = 1
          item.action_list = item['action']

        }
        else if (item['action'] == "Rejected") {
          action_list_status = 2
          item.action_list = item['action']
        }
        else {
          action_list_status = 0
          item.action_list = ""
        }


        if (item['purchase_request']) {
          item['purchase_request']
        }
        if (item['vin']) {
          item.vin = item['vin']
        }




        if (this.auction_id != "new-auction") {
          if ((this.workflow_state == "Price Proposal") && (item.proposed_price <= 0 || item.proposed_price == null)) {
            const errorMsg = '<h4>Please enter a proposed price for all Opportunities</h4>';
            let toast_comp = <ToastMsg msg={errorMsg} />
            this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
            const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }
          }
          if ((this.workflow_state == "Price Estimation" || this.workflow_state == "Price Proposal(Pending Approval)") && ((item.proposed_price == 0 || item.proposed_price == null) && (item.action == "Approved"))) {
            const errorMsg = '<h4>here are approved opportunities without proposed price</h4>';
            let toast_comp = <ToastMsg msg={errorMsg} />
            this.$toast.error(toast_comp, { position:"top-center", duration: 10000 })
            const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }
          }
        }



        auction_items_list.push({
          name: item.name,
          make_id: item.make || "",
          model_id: item.model || "",
          trim_id: item.trim || "",
          make_year: item.make_year || "",
          color_id: item.color || "",
          odometer: item.odometer || "",
          // number_of_cabins: item.number_of_cabins || "",
          engine_size: item.engine_size || "",
          cylinders_number: item.cylinders_number || "",
          plate_text_1: item.plate_text_1 || "",
          plate_text_2: item.plate_text_2 || "",
          plate_text_3: item.plate_text_3 || "",
          plate_number: item.plate_number || "",
          origin: item.origin,
          inspect: item.inspect,
          user: item.user || "",
          recommendation: item.recommendation || "",
          estimated_cost: item.estimated_cost || 0,
          estimated_costs: item.estimated_costs || 0,
          proposed_price: item.proposed_price || 0,
          total_proposed_price_without_tax: item.total_proposed_price_without_tax || 0,
          added_cost: item.added_cost || 0,
          estimated_selling: item.estimated_selling || 0,
          profit_: item.profit_ || 0,
          result: item.result || 0,
          action_list_status: action_list_status || "",
          workflow_state: this.workflow_state || "",
          action: item.action_list || "",
          purchase_request: item.purchase_request || "",
          vin: item.vin || "",
          cahnge_workflow_Price_Proposal: this.cahnge_workflow_Price_Proposal,
          po_status: item.po_status || "",
          fuel: item.fuel || "",
          drivetrain: item.drivetrain || ""
        })

      }
      let duplicatedItem = null;

        let isDuplicate = plate_numbers_list.some(function (item, idx) {
          if (plate_numbers_list.indexOf(item) !== idx) {
            duplicatedItem = item;
            return true;
          }
          return false;
        });

        console.log("isDuplicate", isDuplicate);
        console.log("duplicatedItem", duplicatedItem);

        if (isDuplicate) {
          const errorMsg = `<h4>There is a duplicated plate number: ${duplicatedItem}</h4>`;
          let toast_comp = <ToastMsg msg={errorMsg} />
          this.$toast.error(toast_comp, { position:"top-center", duration: 10000 });
          const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }
          return;
        }

      let auction_dict = {
        "supplier_name": this.selectedSupplier,
        "date": this.selectedDate,
        "city_name": this.selectedCity,
        "type": this.selectedAuctionType,
        "number_of_cars": this.number_of_cars,
        "location": this.location,
        "cars_information_unavailable": this.carsInformationUnavailable,
        "auction_document": this.file_url,
        "auction_id": this.auction_id
      }
      if (plates_list.length == 0) {
        plates_list.push(plate_string)
      }
      let auction_cars_dict = {
        "auction_id": this.auction_id,
        "cars": auction_items_list,
        "plates": plates_list
      }
      console.log("auction_cars_dict",auction_cars_dict)
      let apiUrl = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.on_save_auction`;

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Frappe-CSRF-Token': this.$store.state.CSRF_TOKEN
            },
            body: JSON.stringify({
                sid: this.$store.state.SID,
                auction_dict: auction_dict,
                auction_cars_dict: auction_cars_dict
            })
        });

        const data = await response.json();

        if (data.message.includes("AUC")) {
            this.$router.push({ name: 'AuctionPage', params: { auction_id: data.message } });
        } else if (data.message) {
          if ((this.selectedAuctionType != "Wholesale"  ) && this.carsInformationUnavailable == false) {
            const errorMsg = '<h4>' + data.message + '</h4>';
            let toast_comp = <ToastMsg msg={errorMsg} />;
            this.$toast.error(toast_comp, { position: "top-center", duration: 10000 });
            const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }
            return;
        }
      }
        this.$store.commit('incrementRefreshKey');
        this.$toast.success("Saved!", { position: "bottom-right", timeout: 1000 });
        await this.get_workflow_actions();
        setTimeout(() => {
            this.changedKeys = false;
        }, 300);
        console.log('reached end saving')
        const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }
    } catch (error) {
      const loadingIndicator = document.querySelector('.loading-indicator');
          if (loadingIndicator) {
              loadingIndicator.remove();
          }
    }
    }
  },

  mounted() {
    const vm = this;

    fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_all_suppliers?sid=${this.$store.state.SID}&lang=${this.$store.state.LANGUAGE}`)
      .then(response => response.json())

      .then(data => {
        const supplier = data.message;

        if (supplier && supplier.length > 0) {
          vm.supplier = supplier;
          const supplierNamesArray = vm.supplier.map(supplier => supplier.supplier_name);
          vm.supplier = supplierNamesArray
        }
      })
      .catch(error => {
        console.error('Error fetching suppliers:', error);
      });

    fetch(`${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_all_city?sid=${this.$store.state.SID}&lang=${this.$store.state.LANGUAGE}`)
      .then(response => response.json())
      .then(data => {
        const city = data.message;
        if (city && city.length > 0) {
          vm.city = city;
          const cityNamesArray = vm.city.map(city => city.city_name);
          vm.city = cityNamesArray
        }
      })
      .catch(error => {
        console.error('Error fetching cities:', error);
      });
    this.get_workflow_actions()

  },

  created: function () {
    let url = window.location.href
    let parts = url.split('/');
    let auction_id = parts[parts.length - 1];


    if (auction_id) {
      this.auction_id = auction_id;
    }


    this.$nextTick(function () {
      if (this.auction_id != "") {
        this.get_auction_data();


      }

    });

  },
};
</script>

<style scoped>
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

#title {
  color: rgb(47, 25, 105);
  font-weight: bold;
}

#title_status {
  color: rgb(219, 208, 43);
}
.actionItem:hover {
  background: #ddd;
  cursor: pointer;
}
.actionButton , .importButton{
  position: relative !important;
  width: 8rem !important;
}

.printButton {
  position: relative !important;
  width: 8rem !important;
}
.printItem:hover {
  background: #ddd;
  cursor: pointer;
}
.saveButton {
  position: relative !important;
  width: 8rem !important;
}
</style>